import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Rating,
  Typography,
  useMediaQuery,
  useTheme,
  Link,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import api from "services/api";
import StarIcon from "@mui/icons-material/Star";
import Loader from "shared-components/views/components/Loader";

const AiTocLabel = () => {
  const [stats, setStats] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const goToCreation = () => {
    navigate("/ai-toc");
  };

  useEffect(() => {
    api.get("/ai-toc/stats").then(setStats);
  }, []);

  if (!stats) {
    return <Loader />;
  }

  return (
    <Stack padding={{ xs: 0, lg: 6 }} spacing={5} sx={{ m: 5 }}>
      <Typography
        variant="h1"
        sx={{
          fontFamily: "Inter",
          fontSize: { xs: "32px", sm: "40px", md: "56px" }, // Ajuste responsivo
          fontWeight: 700,
          lineHeight: "1.2",
          textAlign: { xs: "center", md: "left" },
          maxWidth: 750,
        }}
      >
        Use AI to develop your measurement system
      </Typography>

      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Button
          onClick={goToCreation}
          fullWidth={isMobileView}
          bgcolor={"#2568F6"}
          sx={{
            maxWidth: { xs: "80%", sm: "400px", height: "60px" },
            fontFamily: "Inter",
            fontSize: "16px !important",
            fontWeight: 700,
            lineHeight: "26px",
            ml: "38px !important",
          }}
        >
          Create theory of change for free
        </Button>
        <Box
          display="flex"
          alignItems="center"
          gap={2}
          sx={{ justifyContent: "center" }}
        >
          <Rating
            precision={0.1}
            defaultValue={stats.rating}
            sx={{ pointerEvents: "none" }}
            size="large"
            emptyIcon={
              <StarIcon
                style={{ color: theme.palette.secondary.light }}
                fontSize="inherit"
              />
            }
          />
          <Typography variant="subtitle">
            {stats.ratingTotal} innovators rated
          </Typography>
        </Box>
      </Stack>

      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Stack
          direction={{ xs: "row" }}
          divider={<Divider orientation="vertical" flexItem />} // Agregamos el Divider vertical aquí
          spacing={2} // Ajustamos el espacio entre los elementos
          alignItems="center" // Centramos los elementos verticalmente
          justifyContent="center" // Centramos los elementos horizontalmente
        >
          <Stack alignItems="center">
            <Typography
              sx={{
                width: 200,
                textAlign: {
                  xs: "center", // mobile devices
                  sm: "left", // larger screens
                },
              }}
              variant="caption"
            >
              AI ToC created today
            </Typography>
            <Typography
              sx={{
                mt: 0.5,
                fontFamily: "Inter",
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "38.73px",
                textAlign: {
                  xs: "center", // mobile devices
                  sm: "left", // larger screens
                },
                width: "100%",
              }}
            >
              {stats.today}
            </Typography>
          </Stack>

          <Stack alignItems="center">
            <Typography
              sx={{
                width: 200,
                textAlign: {
                  xs: "center", // mobile devices
                  sm: "left", // larger screens
                },
              }}
              variant="caption"
            >
              AI ToC created since {stats.since}
            </Typography>
            <Typography
              sx={{
                mt: 0.5,
                fontFamily: "Inter",
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "38.73px",
                textAlign: {
                  xs: "center", // mobile devices
                  sm: "left", // larger screens
                },
                width: "100%",
              }}
            >
              {stats.all}
            </Typography>
          </Stack>
        </Stack>

        <Stack width={"100%"} alignItems={isMobileView ? "center" : "left"}>
          <Typography sx={{ width: 200 }} variant="caption">
            <span style={{ fontWeight: "bold" }}>Your privacy</span> is our top
            priority. We will not use or share your information with third
            parties.
          </Typography>
          <Box
          // sx={{
          //   width: 200,
          //   ml: 10,
          // }}
          >
            <Typography variant="caption" mt={1}>
              <Link
                href="https://www.impactforesight.io/privacy"
                color="inherit"
                sx={{
                  textDecoration: "none",
                  "&:hover": { textDecoration: "underline" },
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy
              </Link>
              <Link
                href="https://www.impactforesight.io/terms"
                color="inherit"
                px={1}
                sx={{
                  textDecoration: "none",
                  "&:hover": { textDecoration: "underline" },
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms
              </Link>
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AiTocLabel;
