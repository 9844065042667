import React, { useEffect, useState } from "react";
import { useMediaQuery, useTheme, Stack } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
// import api from "services/api";
import AiTocFooter from "./components/AiTocFooter";

// import Loader from "shared-components/views/components/Loader";
import AiTocSeeStatistics from "./AiTocSeeStatistics";
import AiArticleBanner from "./components/AiTocArticleBanner";
import CompanyLogos from "./components/AiTocCompanyLogos";
import StepsList from "./components/AiTocStepsList";
import AiTocLabel from "./components/AiTocLabel";

const AiTocLanding = () => {
  // const [stats, setStats] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));
  // const location = useLocation();

  const goToCreation = () => {
    navigate("/ai-toc");
  };

  // useEffect(() => {
  //   api.get("/ai-toc/stats").then(setStats);
  // }, []);

  // if (!stats) {
  //   return <Loader />;
  // }

  return (
    <Stack
      sx={{
        background: "linear-gradient(143.37deg, #E5E7F4 5.7%, #FFFFFF 78.68%)",
      }}
    >
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        padding={4}
        sx={{
          justifyContent: "center !important",
          alignItems: "center !important",
          mr: 1.1,
        }}
      >
        <AiTocLabel />
        <CompanyLogos />
      </Stack>
      <AiArticleBanner />
      <Stack m={10}>
        <StepsList />
        {!isMobileView && <AiTocSeeStatistics anchor={location.hash} />}
      </Stack>
      <AiTocFooter values={{}} showFeedbackButton={true} />
    </Stack>
  );
};

export default AiTocLanding;
