import React from "react";
import { Stack, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const AiArticleBanner = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true });

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  const variants = {
    visible: { opacity: 2, y: 0 },
    hidden: { opacity: 0, y: -150 },
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={variants}
      transition={{ duration: 1 }}
    >
      <Stack
        sx={{
          backgroundImage: "url(/images/new/planet.jpeg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          objectFit: "cover",
          height: {
            xs: "257px",
            lg: "360px",
          },
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "14.52px",
            textAlign: "left",
            color: "#45A6FF",
            marginBottom: "16px",
            ml: {
              xs: "16px",
              lg: "100px",
            },
          }}
        >
          <span style={{ fontWeight: 700 }}>ImpactForesight</span> 5 days ago
        </Typography>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "44px",
            fontWeight: 600,
            lineHeight: "53.25px",
            textAlign: "left",
            color: "white",
            fontSize: {
              xs: "24px",
              lg: "44px",
            },
            ml: {
              xs: "16px",
              lg: "100px",
            },
          }}
        >
          Artificial Intelligence to create
        </Typography>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "44px",
            fontWeight: 600,
            lineHeight: "53.25px",
            textAlign: "left",
            color: "white",
            fontSize: {
              xs: "24px",
              lg: "44px",
            },
            ml: {
              xs: "16px",
              lg: "100px",
            },
          }}
        >
          a Theory of Change
        </Typography>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "22px",
            textAlign: "left",
            marginBottom: "16px",
            color: "white",
            fontSize: {
              xs: "14px",
              lg: "16px",
            },
            ml: {
              xs: "16px",
              lg: "100px",
            },
          }}
        >
          A complete theory of change within a few minutes
        </Typography>
        <Typography
          component="a"
          href="https://www.impactforesight.io/article/ai-theory-of-change"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: 700,
            lineHeight: "14.52px",
            textAlign: "left",
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            color: "inherit",
            "&:hover": {
              textDecoration: "underline",
            },
            color: "white",
            fontSize: {
              xs: "12px",
              lg: "16px",
            },
            ml: {
              xs: "16px",
              lg: "100px",
            },
          }}
        >
          Read article <ArrowForwardIcon sx={{ marginLeft: 1 }} />
        </Typography>
      </Stack>
    </motion.div>
  );
};

export default AiArticleBanner;
