import React from "react";
import { Box, Typography, Stack, Grid, Divider } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const CompanyLogos = () => {
  return (
    <Stack
      direction="column"
      divider={<Divider orientation="horizontal" flexItem />}
      spacing={1}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        height: {
          xs: "320px",
          sm: "360px",
        },
      }}
      borderRadius={"16px"}
      borderColor={"white"}
      bgcolor={"white"}
      width={"360px"}
    >
      <Typography
        variant="body1"
        color="grey"
        sx={{
          mb: 2,
          textAlign: "center",
          fontFamily: "Inter",
          fontSize: "12px",
          fontWeight: 600,
          lineHeight: "14.52px",
          display: "flex",
          alignItems: "center",
          textDecoration: "none",
          color: "#758BAA",
        }}
      >
        JOIN LEADING COMPANIES USING OUR SERVICES
      </Typography>

      <Stack spacing={4} width="100%" alignItems="center" padding={2}>
        <Grid container justifyContent="center">
          <Grid item xs={12} container justifyContent="center" marginBottom={1}>
            <Box px={2} height={{ xs: 40, sm: 50, md: 60 }} sx={{ mb: 2 }}>
              <a
                href="https://mitsloan.mit.edu/sustainability-initiative/welcome"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  width="180.22px"
                  height="47.64px"
                  src="/images/new/mitsloan.png"
                  alt="Mit Sloan Sustainability Initiative"
                  style={{ cursor: "pointer" }}
                />
              </a>
            </Box>
          </Grid>

          <Grid item xs={12} container justifyContent="center" marginBottom={1}>
            <Box px={2} height={{ xs: 40, sm: 50, md: 60 }} sx={{ mb: 2 }}>
              <a
                href="https://pkgcenter.mit.edu/programs/ideas/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  width="240px"
                  height="50px"
                  src="/images/new/mit-inno.png"
                  alt="PKG Ideas Social Innovation Challenge"
                  style={{ cursor: "pointer" }}
                />
              </a>
            </Box>
          </Grid>

          <Grid item xs={12} container justifyContent="center" marginBottom={1}>
            <Box px={2} height={{ xs: 40, sm: 50, md: 40 }}>
              <a
                href=" https://yunusandyouth.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  width="170px"
                  height="60px"
                  src="/images/new/youth.png"
                  alt="YUNUS and YOUTH"
                  style={{ cursor: "pointer" }}
                />
              </a>
            </Box>
          </Grid>
        </Grid>
      </Stack>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap={2}
        mt={2}
        marginBottom={25}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor="lightgrey"
          p={1}
          borderRadius="100%"
        >
          <ExpandMoreIcon sx={{ fontSize: 20 }} />
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor="lightgrey"
          p={1}
          borderRadius="100%"
          sx={{ opacity: 0.5 }}
        >
          <ExpandLessIcon sx={{ fontSize: 20 }} />
        </Box>
      </Box>
    </Stack>
  );
};

export default CompanyLogos;
