import React from "react";
import { Grid, Box, Typography, Stack } from "@mui/material";

const StepsList = () => {
  return (
    <Grid container spacing={5} mb={6}>
      <Grid item xs={12} sm={4} display="flex" justifyContent="center">
        <Stack
          spacing={2}
          sx={{
            justifyContent: "center",
            alignItems: { xs: "center", sm: "flex-start" }, // Center on mobile, align left on larger screens
            textAlign: { xs: "center", sm: "left" }, // Text centered on mobile
          }}
          direction={{ xs: "column", sm: "row" }} // Column on mobile, row on larger screens
        >
          <Box
            component="img"
            width={80}
            height={80}
            alt="1. Generate"
            src="/images/icons/ai-toc/Frame43.svg"
          />
          <Box>
            <Typography variant="bodyBold">
              1. Generate AI Theory of Change
            </Typography>
            <Typography variant="caption" sx={{ lineHeight: "1.2", mt: 0.5 }}>
              It takes a few minutes and does
            </Typography>
            <Typography variant="caption" sx={{ lineHeight: "1.2", mt: 0.5 }}>
              not require any login or payment.
            </Typography>
          </Box>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={4} display="flex" justifyContent="center">
        <Stack
          spacing={2}
          sx={{
            justifyContent: "center",
            alignItems: { xs: "center", sm: "flex-start" }, // Center on mobile, align left on larger screens
            textAlign: { xs: "center", sm: "left" }, // Text centered on mobile
          }}
          direction={{ xs: "column", sm: "row" }} // Column on mobile, row on larger screens
        >
          <Box
            component="img"
            width={80}
            height={80}
            alt="2. Feedback"
            src="/images/icons/ai-toc/Frame40.svg"
          />
          <Box>
            <Typography variant="bodyBold">
              2. Save to edit and refine
            </Typography>
            <Typography variant="caption" sx={{ lineHeight: "1.2", mt: 0.5 }}>
              Option to create free account (first 2 weeks free trial).
            </Typography>
          </Box>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={4} display="flex" justifyContent="center">
        <Stack
          spacing={2}
          sx={{
            justifyContent: "center",
            alignItems: { xs: "center", sm: "flex-start" }, // Center on mobile, align left on larger screens
            textAlign: { xs: "center", sm: "left" }, // Text centered on mobile
          }}
          direction={{ xs: "column", sm: "row" }} // Column on mobile, row on larger screens
        >
          <Box
            component="img"
            width={80}
            height={80}
            alt="3. Account"
            src="/images/icons/ai-toc/Frame42.svg"
          />
          <Box>
            <Typography variant="bodyBold">3. Manage your impact</Typography>
            <Typography variant="caption" sx={{ lineHeight: "1.2", mt: 0.5 }}>
              AI impact pitch deck, set targets,
            </Typography>
            <Typography variant="caption" sx={{ lineHeight: "1.2", mt: 0.5 }}>
              monitor progress, public profile and more.
            </Typography>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default StepsList;
